import React from "react"
import * as styles from "./index.module.scss"

export type PatientData = {
  firstName: string
  lastName: string
  sex: string
  dateOfBirth: string
}
const PatientData = ({
  firstName,
  lastName,
  sex,
  dateOfBirth,
}: PatientData) => {
  return (
    <div>
      <div className="canopy-typography-heading-large">New order for</div>
      <span className="canopy-typography-heading-large">
        {firstName} {lastName}
      </span>{" "}
      {sex} &#x2022; {dateOfBirth}
    </div>
  )
}

const SupplierOrganizationInboxDocument = ({
  patient,
}: {
  patient: PatientData
}) => {
  return (
    <div className={styles.columnContainer}>
      <div className={styles.documentColumn}></div>
      <div className={styles.dataEntryColumn}>
        <PatientData {...patient} />
      </div>
    </div>
  )
}
export default SupplierOrganizationInboxDocument
