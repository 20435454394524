import {
  CanopyComboboxField,
  CanopyComboboxFieldProps,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import React, { useCallback } from "react"
import { debounce, uniqWith, isEqual, flatten, uniq } from "lodash"

export type FetchingComboboxProps<T> = {
  /**
   * Will be fired when the user changes the selection by choosing an option (or additional options).
   * This will yield an array of the chosen object yielded by the fetch function, not the simple option object
   * used internally by Canopy.
   */
  onSelection?: (newValue: T[]) => void
  /**
   * A function that will asynchronously fetch items based upon provided search text.
   * It doesn't have to be an XHR request, but that is the most common use case.
   */
  fetchItems: (searchValue: string) => Promise<T[]>
  /**
   * Provide the combobox a way to determine what string shows up as the visible label of the option.
   */
  getOptionLabel: (option: T) => string
  /**
   * Provide the combobox a way to determine the value of the object. If not specified, it will look at the
   * "id" of T, under the assumption that T is probably an ActiveRecord object.
   */
  getOptionValue?: (option: T) => string
  /**
   * Options will be disabled for selection if the passed function returns true.
   * Useful for, e.g., preventing multiple selection.
   */
  disableOption?: (option: T) => boolean
  /**
   * Set the pre-selected values, if any.
   */
  defaultValue?: T | T[] | null
  /**
   * The length of time after a user stops typing before the fetch is called; defaults to 500ms.
   */
  debounceDelay?: number
  /**
   * The Canopy field label for this autocomplete
   */
  label?: string
  /**
   * The (optional) placeholder string.
   */
  placeholder?: string
} & Omit<CanopyComboboxFieldProps, "options" | "ref" | "defaultValue">

/**
 * A Canopy-based autocomplete component that fetches data asynchronously based on user-provided search values
 * and a data-fetching function that the consuming component provides.
 *
 * While this component allows for specifying either single or multiple selections, for simplicity's sake
 * it will always yield back an array of selected items. If `multiple === false`, then the array yielded
 * to the `onSelection` function will either be an empty array or an array of length 1.
 *
 * WARNING! Using this component in multiple mode is full of dragons due to limitations of the wrapped component!
 * It also is not suitable for use in Canopy Dialog Modals, as the dropdown portion will be clipped by the container.
 */
const FetchingCombobox = <T,>(
  props: FetchingComboboxProps<T>
): React.JSX.Element => {
  const idValueFetcher = (option: T): string => {
    return option["id"].toString()
  }

  const {
    label = "",
    onSelection,
    multiple,
    fetchItems,
    debounceDelay = 500,
    getOptionLabel,
    defaultValue: defaultValueFromProps = [],
    getOptionValue = idValueFetcher,
    disableOption = () => false,
    ...other
  } = props
  const defaultValue = defaultValueFromProps
    ? flatten([defaultValueFromProps])
    : []
  const [loading, setLoading] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState<T[]>(defaultValue)
  const [selectedItems, setSelectedItems] = React.useState<T[]>(defaultValue)
  const selectedText = React.useRef<string | null>(null)

  const handleSearch = async (query: string): Promise<void> => {
    const items: T[] = dedupe(await handleFetch(query))

    setOptions(items)
    setLoading(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce(handleSearch, debounceDelay),
    [debounceDelay]
  )

  React.useEffect(() => {
    // cancel any pending async calls during teardown
    return debouncedHandleSearch.cancel()
  }, [debouncedHandleSearch])

  const updateSelected = (items: OptionItem[]): void => {
    const ids: Set<string> = new Set(items.map((item) => item.value))
    const selected: T[] = options.filter((o) => ids.has(getOptionValue(o)))

    if (multiple) {
      setSelectedItems(
        uniq<T>([...selectedItems, ...selected])
      )
    } else {
      setSelectedItems(selected)
      if (selected.at(0)) {
        // needed for inspection in onInputChange
        selectedText.current = getOptionLabel(selected.at(0)!)
      }
    }

    if (onSelection) {
      onSelection(selected)
    }
  }

  const selectedValues = (): OptionItem | OptionItem[] => {
    const selected: OptionItem[] = selectedItems.map(typeToOption)

    if (multiple) {
      return selected
    } else {
      return selected[0]
    }
  }

  const onChange = (newValue: null | OptionItem | OptionItem[]): void => {
    // arrays are the lingua franca; coerce non-arrays into an array
    const wrappedIncomingValue: OptionItem[] = flatten([newValue || []])

    updateSelected(wrappedIncomingValue)
  }

  /**
   * Sort items by their labels.
   */
  const labelComparator = (a: T, b: T): number => {
    return getOptionLabel(a).localeCompare(getOptionLabel(b))
  }

  /**
   * Merge previously-selected options with newly-fetched options (if applicable).
   * This method will also sort them, so existing options may appear interspersed throughout the other results.
   */
  const dedupe = (fetched: T[]): T[] => {
    if (!multiple) return fetched

    if (selectedItems.length === 0) return fetched

    const optionSelected = <T,>(optionA: T, optionB: T) => {
      return isEqual(optionA, optionB)
    }

    return uniqWith<T>([...selectedItems, ...fetched], optionSelected).sort(
      labelComparator
    )
  }

  const onInputChange = (newValue: string): void => {
    // Work around an issue where selecting an item in non-multiple mode
    // set the input text, which triggers another async query.
    // The selectedText ref is updated onChange, so we compare to see if what we most recently set it to
    // matches what just popped into the input.
    if (selectedText.current !== newValue) {
      void debouncedHandleSearch(newValue)
    }
  }

  const handleFetch = async (query: string): Promise<T[]> => {
    setLoading(true)
    return await fetchItems(query)
  }

  /**
   * Whatever our type is, extract the necessary values to use as a combobox option.
   */
  const typeToOption = (item: T): OptionItem => {
    return {
      label: getOptionLabel(item),
      value: getOptionValue(item),
      disabled: disableOption(item),
    }
  }

  /**
   * Convert the whole current list of options to a format that the combobox can use.
   */
  const canopyOptions: OptionItem[] = React.useMemo(() => {
    return options.map(typeToOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const defaultSelectedValues = (): string[] => {
    return defaultValue.map(getOptionValue)
  }

  return (
    <CanopyComboboxField
      placeholder="Type to search"
      loading={loading}
      multiple={multiple}
      onInputChange={onInputChange}
      label={label}
      value={selectedValues()}
      onChange={onChange}
      options={canopyOptions}
      defaultValue={defaultSelectedValues()}
      {...other}
    />
  )
}

export default FetchingCombobox
